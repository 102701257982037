<template>
  <section v-if="siteData.length > 0">
    <b-row class="row row-cols-2 row-cols-md-4">
      <!-- solid color -->
      <b-col class="mb-2" v-for="(menu, index) in menuCards" :key="index">
        <b-card
          :bg-variant="menu.background"
          class="text-center h-100"
          text-variant="white"
        >
          <b-avatar :variant="menu.background" size="70" class="shadow mb-1">
            <feather-icon size="28" :icon="menu.icon" />
          </b-avatar>
          <b-card-title class="text-white mb-0">
            {{ menu.title }}
          </b-card-title>
        </b-card>
      </b-col>
    </b-row>
    <!-- Site Select -->

    <b-card class="mb-4">
      <h4 align="center" class="mb-2">Sites</h4>
      <b-container>
        <b-row>
          <Flicking
            ref="siteFlicking"
            class="ml-0 mr-0"
            :options="siteFlicker"
            @before-resize="panelControl('siteResize', $event)"
            @will-change="panelControl('sitePaginate', $event)"
          >
            <div class="col-md-4 panel" v-for="index in rowCount" :key="index">
              <b-button
                v-for="button in siteData.slice(index * 2, (index + 1) * 2)"
                :key="button.index"
                class="mb-1"
                block
                @click="resourceSelect(button, 'rdps')"
                variant="primary"
              >
                {{ button.name }}
              </b-button>
            </div>
          </Flicking>
        </b-row>
      </b-container>
      <div>
        <b-col cols="12">
          <b-pagination
            v-model="siteFlicker.currentPage"
            v-show="siteData.length > 4"
            per-page="1"
            align="center"
            :total-rows="siteFlicker.pagination"
            @input="panelControl('siteFlicker', $event)"
          />
        </b-col>
      </div>
    </b-card>

    <!-- Resource Select Cards -->
    <b-card>
      <h3 align="center">
        {{ this.selectedSite.name }}
      </h3>
      <b-row>
        <b-col align="center" class="mb-2">
          <b-button
            variant="success"
            v-if="this.selectedSite.rdps.length > 0"
            @click="resourceSelect(selectedSite, 'rdps')"
            class="mr-1"
            >RDP</b-button
          >
          <b-button
            variant="danger"
            v-if="this.selectedSite.cameras.length > 0"
            @click="resourceSelect(selectedSite, 'cameras')"
            >Cameras</b-button
          >
        </b-col>
      </b-row>
      <b-container>
        <b-row>
          <Flicking
            ref="resourceFlicking"
            :options="resourceFlicker"
            @before-resize="panelControl('resourceResize', $event)"
            @will-change="panelControl('resourcePaginate', $event)"
          >
            <div
              v-for="(resource, index) in selectedResource"
              :key="index"
              class="col-md-4 col-sm-12"
            >
              <b-card
                :img-src="require('@/assets/images/pages/camera.png')"
                img-alt="Card image cap"
                img-top
                class="border shadow-lg"
                no-body
              >
                <b-card-body class="text-center">
                  <b-card-title>{{ resource.name }}</b-card-title>
                  <b-card-text> {{ selectedSite.name }}</b-card-text>
                  <b-button
                    :variant="selectedResourceCard.variant"
                    target="_blank"
                    :href="resource.url"
                    @click="getRemoteAccess(resource)"
                  >
                    {{ selectedResourceCard.title }}
                  </b-button>
                </b-card-body>
              </b-card>
            </div>
          </Flicking>
        </b-row>
        <div>
          <b-col cols="12">
            <b-pagination
              v-model="resourceFlicker.currentPage"
              hide-goto-end-buttons
              per-page="1"
              align="center"
              class="align-self-center"
              v-show="selectedResource.length > 3"
              @input="panelControl('resourceFlicker', $event)"
              :total-rows="resourceFlicker.pagination"
            />
          </b-col>
        </div>
      </b-container>
    </b-card>
  </section>
  <section v-else>
    <h1>Hello, and Welcome to Opal</h1>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BAvatar,
  BButton,
  BCardBody,
  BCardTitle,
  BContainer,
  BPagination
} from 'bootstrap-vue'
import { site } from '@/services/site.service.js'
import { Flicking } from '@egjs/vue-flicking'
import '@egjs/vue-flicking/dist/flicking.css'

export default {
  components: {
    Flicking,
    BContainer,
    BRow,
    BCardText,
    BPagination,
    BCol,
    BCard,
    BCardBody,
    BCardTitle,
    BButton,
    BAvatar
  },
  data() {
    return {
      perPage: 3,
      totalRows: '',
      currentPage: 1,
      paginatedItems: [],
      selectedSite: {
        name: '',
        protocol: ''
      },
      siteFlicker: {
        moveType: ['snap', { stopAtEdge: true }],
        align: 'prev',
        bound: true,
        currentPage: 1,
        resizeOnContentsReady: true,
        pagination: 1
      },
      resourceFlicker: {
        moveType: ['snap', { stopAtEdge: true }],
        align: 'prev',
        bound: true,
        currentPage: 1,
        pagination: 1
      },
      selectedSite: {
        name: '',
        cameras: [],
        rdps: [
          {
            name: '',
            protocol: ''
          }
        ],
        quicklinks: []
      },
      selectedResource: {},
      selectedResourceCard: {},
      siteData: [
        {
          name: '',
          cameras: [],
          rdps: []
        }
      ],
      menuCards: [
        {
          background: 'success',
          title: 'Remote Desktops',
          icon: 'MonitorIcon',
          iconColor: 'success'
        },
        {
          background: 'danger',
          title: 'Cameras',
          icon: 'VideoIcon'
        },
        {
          background: 'warning',
          title: 'Quick Links',
          icon: 'BookmarkIcon'
        },
        {
          background: 'info',
          title: 'Maps',
          icon: 'MapIcon'
        }
      ]
    }
  },

  methods: {
    resourceSelect(site, type) {
      this.selectedSite = site
      // new switch statement
      if (type === 'rdps' && site.rdps.length === 0) {
        this.selectedResourceCard.title = 'Live Stream'
        this.selectedResourceCard.variant = 'danger'
        this.selectedResource = site.cameras
      }
      if (type === 'rdps' && site.rdps.length > 0) {
        this.selectedResource = site.rdps
        this.selectedResourceCard.title = 'Connect'
        this.selectedResource.rdps = true
        this.selectedResourceCard.variant = 'success'
      }
      if (type === 'cameras') {
        this.selectedResource = site.cameras
        this.selectedResourceCard.title = 'Live Stream'
        this.selectedResourceCard.variant = 'danger'
      }
      if (type === 'quicklinks') {
        this.selectedResource = site.quicklinks
        this.totalRows = site.quicklinks.length
      }
      this.$refs.resourceFlicking
        .moveTo(0)
        .then(() => {
          this.resourceFlicker.currentPage = 1
          this.resourceFlicker.pagination =
            this.$refs.resourceFlicking.panelCount - 2
        })
        .catch((err) => {})
    },
    panelControl(type, event) {
      if (type === 'siteResize') {
        if (
          this.$store.getters['app/currentBreakPoint'] === 'sm' ||
          this.$store.getters['app/currentBreakPoint'] === 'xs'
        ) {
          this.siteFlicker.pagination = this.$refs.siteFlicking.panelCount
        } else {
          this.siteFlicker.pagination = this.$refs.siteFlicking.panelCount - 2
        }
      }
      if (type === 'resourceResize') {
        if (
          this.$store.getters['app/currentBreakPoint'] === 'sm' ||
          this.$store.getters['app/currentBreakPoint'] === 'xs'
        ) {
          this.resourceFlicker.pagination =
            this.$refs.resourceFlicking.panelCount
        } else {
          this.resourceFlicker.pagination =
            this.$refs.resourceFlicking.panelCount - 2
        }
      }
      if (type === 'siteFlicker') {
        const moveToPage = event - 1
        this.$refs.siteFlicking
          .moveTo(moveToPage)
          .then(
            // if next is successful
            () => {}
          )
          .catch(() => {})
      }
      if (type === 'resourceFlicker') {
        const moveToPage = event - 1
        this.$refs.resourceFlicking
          .moveTo(moveToPage)
          .then(
            // if next is successful
            () => {}
          )
          .catch(() => {})
      }
      if (type === 'sitePaginate') {
        this.siteFlicker.currentPage = event.index + 1
      }
      if (type === 'resourcePaginate') {
        this.resourceFlicker.currentPage = event.index + 1
      }
    },
    async getRemoteAccess() {
      try {
        site.getRemoteAccess()
      } catch (error) {
        window.Bus.instance.$emit('pageDanger', {
          header: 'ERROR',
          detail: error.response.data
            ? error.response.data.error
            : error.response.statusText
        })
      }
    },

    async getAllSites() {
      try {
        site.getAllSites().then((data) => {
          this.siteData = data.data.data
          this.resourceSelect(this.siteData[0], 'rdps')
        })
      } catch (error) {
        window.Bus.instance.$emit('pageDanger', {
          header: 'ERROR',
          detail: error.response.data
            ? error.response.data.error
            : error.response.statusText
        })
      }
      return 'loaded'
    }
  },
  // created computed property to watch the window resize
  computed: {
    breakPoint() {
      const breakPoint = this.$store.getters['app/currentBreakPoint']
      if (breakPoint === 'xs') {
        return 2
      }
      if (breakPoint === 'sm') {
        return 2
      }
      if (breakPoint === 'md') {
        return 2
      }
      if (breakPoint === 'lg') {
        return 2
      }
      if (breakPoint === 'xl') {
        return 2
      }
    },
    rowCount() {
      return Array.from(
        Array(Math.ceil(this.siteData.length / this.breakPoint)).keys()
      )
    }
  },
  mounted() {
    this.getAllSites().then((data) => {
      this.$refs.resourceFlicking.resize()
      this.$refs.siteFlicking.resize()
    })
  }
}
</script>
